<template lang='pug'>
    form.form-finalizar(@submit.prevent='handleSubmit()')
        ProgressBar(v-if='waitingForm' mode="indeterminate")
        div(v-else)
            Panel(header="Lote de faturamento")
                .p-grid.p-fluid.p-align-end

                    .p-col-12.p-md-4
                        label.form-label Descrição do Lote:
                        InputText(v-model='$v.model.ds_pagamento.$model')
                        .feedback--errors(v-if='submitted && $v.model.ds_pagamento.$error')
                            .form-message--error(v-if="!$v.model.ds_pagamento.required") <b>Descrição do Pagamento</b> é obrigatório.

                    .p-col-12.p-md-4
                        label.form-label Estabelecimento:
                        InputText(v-model='model.nm_estabelecimento' readonly disabled)

                    .p-col-6.p-md-4
                        label.form-label Data início:
                        InputText(v-model='model.dt_inicial_f' readonly disabled)

                    .p-col-6.p-md-4
                        label.form-label Data fim:
                        InputText(v-model='model.dt_final_f' readonly disabled)

                    .p-col-6.p-md-4(:class="{'form-group--error': submitted && $v.model.dt_competencia.$error }")
                        label.form-label Data de competência:
                        .p-inputgroup
                            Calendar( v-model='$v.model.dt_competencia.$model' dateFormat="mm/yy" :locale="$parent.ptbr" :manualInput='false'
                                :yearRange="`2020:${proximoAno}`" :yearNavigator='true' view='month')
                            Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='model.dt_competencia = null;')
                        .feedback--errors(v-if='submitted && $v.model.dt_competencia.$error')
                            .form-message--error(v-if="!$v.model.dt_competencia.required") <b>Data de competência</b> é obrigatório.

                    .p-col-6.p-md-4
                        label.form-label Valor bruto:
                        InputText(v-model='model.nr_valorbruto_f' readonly disabled)

                    .p-col-12.p-md-3
                        span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                    .p-col-12.p-md-3
                        span <b>Data da criação: </b>{{ model.dt_criado_f }}
                    .p-col-12.p-md-3
                        span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                    .p-col-12.p-md-3
                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                    .p-col-6
                        a(@click='$parent.finalizar = false' style='cursor:pointer') Visualizar guias

                    //.p-col-6(v-else)
                        ProgressSpinner.waitingRelatorio(v-if='waitingRelatorio' strokeWidth='2' style="width:30px;")
                        a(v-else @click='gerarRelatorio()' style='cursor:pointer') Visualizar guias

                    .p-col-6.ta-right
                        Button(:label=" model.id ? 'Salvar' : 'Gerar faturamento' " icon='jam jam-check' style='max-width:240px')
</template>

<style lang='scss'>
    .faturamento-listar {
        .form-finalizar {
            .p-inputtext[readonly=readonly] {
                background-color: #eee;
                font-weight: 700;
            }
            .p-calendar > .p-inputtext {
                background-color: #fff;
                font-weight: 400;
            }
        }
    }
</style>

<script>
    import InputText from 'primevue/inputtext'
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import Calendar from 'primevue/calendar'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'

    import { required } from 'vuelidate/lib/validators'
    import moment from 'moment'
    import { Bancos, Pagamentos, Relatorio } from '../../middleware'
    export default {
        created () {
            Bancos.findAll().then(response => {
                if (response.status == 200) this.options.bancos = response.data
            })
            setTimeout(() => {
                let el = document.querySelector('.input-nr_descontoinss');
                if (el) { let _setCursorEnd = (ev) => { if (ev.type == 'keydown' && ev.keyCode == 46)
                { this.model.nr_descontoinss = 0 } let pos = el.value.length; el.setSelectionRange(pos, pos) }
                el.onclick = _setCursorEnd; el.onkeydown = _setCursorEnd }

                el = document.querySelector('.input-nr_impostofederal');
                if (el) { let _setCursorEnd = (ev) => { if (ev.type == 'keydown' && ev.keyCode == 46)
                { this.model.nr_impostofederal = 0 } let pos = el.value.length; el.setSelectionRange(pos, pos) }
                el.onclick = _setCursorEnd; el.onkeydown = _setCursorEnd }
            })
        },
        components: { InputText, ProgressBar, Panel, Calendar, Button, Dropdown, ProgressSpinner },
        watch: {
            '$parent.finalizar': function(finalizar) {
                if (finalizar) {

                    if (this.$parent.id && !this.model.id) {

                        this.waitingForm = true
                        Pagamentos.find(this.$parent.id).then(response => {
                            if (response.status === 200) {
                                this.model.id = response.data.id
                                this.model.ds_pagamento = response.data.ds_pagamento
                                this.model.nm_estabelecimento = response.data.cd_estabelecimento.nm_fantasia
                                this.model.cd_estabelecimento = response.data.cd_estabelecimento.id
                                this.model.dt_inicial = response.data.dt_inicial
                                this.model.dt_inicial_f = moment(response.data.dt_inicial).format('DD/MM/YYYY')
                                this.model.dt_final = response.data.dt_final
                                this.model.dt_final_f = moment(response.data.dt_final).format('DD/MM/YYYY')
                                this.model.dt_competencia = moment(response.data.dt_competencia)._d
                                this.model.dt_pagamento = moment(response.data.dt_pagamento)._d
                                this.model.nr_valorbruto = parseFloat(response.data.nr_valorbruto)
                                this.model.nr_valorbruto_f = this.$parent.formatPrice(response.data.nr_valorbruto)
                                this.model.cd_guia_eletronica = response.data.guiaeletronica_set
                                this.model.nm_usuario_cri = response.data.nm_usuario_cri
                                this.model.nm_usuario_edi = response.data.nm_usuario_edi
                                this.model.dt_criado_f = response.data.dt_criado ?
                                    moment(response.data.dt_criado).format('DD/MM/YYYY') : ""
                                this.model.dt_atualizado_f = response.data.dt_atualizado ?
                                    moment(response.data.dt_atualizado).format('DD/MM/YYYY') : ""

                                this.$parent.filters.cd_estabelecimento = response.data.cd_estabelecimento.id
                                this.$parent.filters.dt_execucao_inicial = moment(response.data.dt_inicial)._d
                                this.$parent.filters.dt_execucao_final = moment(response.data.dt_final)._d
                                this.$parent.applyFilters(this.model.id).then(() => {
                                    this.$parent.selected = this.$parent.list.filter(guia => response.data.guiaeletronica_set.includes(guia.id_guia_eletronica))
                                    this.waitingForm = false
                                })
                            }
                        })

                    } else {
                        this.model.dt_inicial_f = moment(this.$parent.filters.dt_execucao_inicial).format('DD/MM/YYYY')
                        this.model.dt_inicial = moment(this.$parent.filters.dt_execucao_inicial).format('YYYY-MM-DD')
                        this.model.dt_final_f = moment(this.$parent.filters.dt_execucao_final).format('DD/MM/YYYY')
                        this.model.dt_final = moment(this.$parent.filters.dt_execucao_final).format('YYYY-MM-DD')
                        this.model.cd_estabelecimento = this.$parent.filters.cd_estabelecimento
                        this.model.nm_estabelecimento = this.$parent.options.estabelecimentos.find(estabelecimento =>
                            estabelecimento.id == this.model.cd_estabelecimento).nm_fantasia
                        this.model.ds_pagamento = (this.$parent.filters.ie_tipo_agenda == 'C')? 'Consulta' : (this.$parent.filters.ie_tipo_agenda == 'E')? 'Exame' : 'Consulta / Exame'


                        let sumGuias = 0
                        this.model.cd_guia_eletronica = []
                        this.$parent.selected.forEach(guia => {
                            this.model.cd_guia_eletronica.push(guia.id_guia_eletronica)
                            sumGuias += parseFloat(guia.valor_repasse)
                        })
                        this.model.nr_valorbruto = parseFloat(sumGuias.toFixed(2))
                        this.model.nr_valorbruto_f = this.$parent.formatPrice(this.model.nr_valorbruto)

                        if (! this.model.dt_competencia) this.model.dt_competencia = moment()._d
                        if (! this.model.dt_pagamento) this.model.dt_pagamento = moment()._d

                        this.$forceUpdate()
                    }
                }
            }
        },
        data () {
            return {
                model: {
                    cd_estabelecimento: null,
                    dt_inicial: null,
                    dt_final: null,
                    ds_pagamento: null,
                    dt_competencia: null,
                    dt_pagamento: null,
                    nr_valorbruto: null,
                    cd_guia_eletronica: [],
                    ie_status_pagamento: 'E'
                },
                options: {
                    bancos: []
                },
                waitingForm: false,
                waitingRelatorio: false,
                submitted: false,
                proximoAno: parseInt(moment().format("YYYY"))+1,
            }
        },
        validations () {
            let v = {
                model: {
                    ds_pagamento: { required },
                    dt_competencia: { required },
                    dt_pagamento: { required },
                    nr_valorbruto: { required },
                }
            }

            return v
        },
        methods: {
            gerarRelatorio () {
                this.waitingRelatorio = true
                Relatorio.getRelatorioPagamento(this.model.id, 'pdf').then(response => {
                    this.waitingRelatorio = false
                    if (response.status == 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            var fileURL = URL.createObjectURL(blob);
                            window.open(fileURL);
                        }
                    }
                })
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                delete dataSend.nm_estabelecimento
                delete dataSend.dt_inicial_f
                delete dataSend.dt_final_f
                delete dataSend.nr_valorbruto_f
                delete dataSend.nr_valorbruto

                dataSend.dt_competencia = moment(dataSend.dt_competencia).format('MM/YYYY')
                dataSend.dt_pagamento = moment(dataSend.dt_pagamento).format('YYYY-MM-DD')

                this.waitingForm = true
                Pagamentos.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! this.model.id) this.$router.push({ name: 'lotes-list' })
                        this.$toast.success('Lotes registrados com sucesso', { duration: 3000 })
                    } else if (([400]).includes(response.status)) {
                        if (response.data)
                            Object.values(response.data).forEach(error => {
                                error.forEach(errorMsg => this.$toast.error(errorMsg, { duration: 3000 }))
                            })
                    }
                    this.waitingForm = false
                })
            }
        }
    }
</script>
